<template>
  <div class="login">
    <div class="main">
      <swiper class="wrapper"
              :options="swiperOption">
        <swiper-slide class="swiper-item">
          <img class="swiper-img"
               src="../assets/img/GWbanner.png"
               alt />
        </swiper-slide>
        <swiper-slide class="swiper-item">
          <img class="swiper-img"
               src="../assets/img/login-banner2.png"
               alt />
        </swiper-slide>
      </swiper>
      <!--登录-->
      <div class="loginCentent"
           v-if="pageStatus == 1">
        <h1>无人机巡检管控平台</h1>
        <!--<h2>JOINT FLIGHT</h2>-->
        <Row type="flex"
             justify="center"
             class="lg">
          <Col span="16"
               class="lg">
          <Form ref="formInline"
                :model="formInline"
                :rules="ruleInline"
                id="loginForm">
            <FormItem prop="user">
              <Row type="flex"
                   justify="center"
                   class="lg">
                <Col span="4"
                     class="lg">
                <div class="icomBox">
                  <img src="../assets/img/Icon/id.png"
                       alt="" />
                </div>
                </Col>
                <Col span="20"
                     class="lg">
                <Input type="text"
                       v-model="formInline.user"></Input>
                </Col>
              </Row>
            </FormItem>
            <FormItem prop="password">
              <Row type="flex"
                   justify="center"
                   class="lg">
                <Col span="4"
                     class="lg">
                <div class="icomBox">
                  <img src="../assets/img/Icon/password.png"
                       alt="" />
                </div>
                </Col>
                <Col span="20"
                     class="lg">
                <Input type="password"
                       v-model="formInline.password"
                       @on-enter="login()"></Input>
                </Col>
              </Row>
            </FormItem>
            <FormItem>
              <Row type="flex"
                   class="lg">
                <Col class="lg">
                <CheckboxGroup v-model="formInline.checkbox">
                  <Checkbox label="记住密码"></Checkbox>
                </CheckboxGroup>
                </Col>
                <!--<Col span="16" class="lg">-->
                <!--<span style="color: #29b8f8;cursor:pointer" @click="pageStatus=2">注册</span>-->
                <!--</Col>-->
              </Row>
            </FormItem>
            <FormItem>
              <Row type="flex"
                   justify="center"
                   class="lg">
                <input type="button"
                       value="登录"
                       class="logIn"
                       @click="login()" />
              </Row>
            </FormItem>
          </Form>
          </Col>
        </Row>
        <div class="Powered">
          <h3 style="">Powered by</h3>
          <img src="../assets/img/Rlogo.png"
               alt="" />
        </div>
      </div>
      <!--验证注册手机号码-->
      <div class="verifyCentent"
           v-if="pageStatus == 2">
        <div class="title">
          <h1>注册</h1>
          <h2>Register</h2>
        </div>
        <Row type="flex"
             justify="center"
             class="lg"
             class-name="verifyBox">
          <Col span="16"
               class="lg">
          <Form ref="verifyForm"
                :model="verifyForm"
                :rules="verifyRule"
                id="verifyForm">
            <FormItem prop="phone">
              <Row type="flex"
                   justify="center"
                   class="lg">
                <Col span="24"
                     class="lg">
                <Input type="text"
                       v-model="verifyForm.phone"
                       placeholder="请输入手机号"></Input>
                </Col>
              </Row>
            </FormItem>
            <FormItem prop="smscode">
              <Row type="flex"
                   justify="space-around"
                   class="lg">
                <Col span="24"
                     class="lg">
                <div class="securityBox">
                  <Input type="password"
                         v-model="verifyForm.smscode"
                         placeholder="短信验证码"></Input>
                  <div class="security"
                       v-if="!reckon_time_shou"
                       @click="sendSecurity">
                    发送验证码
                  </div>
                  <div class="security"
                       :class="reckon_time_ent ? 'bgc' : ''"
                       v-if="reckon_time_shou"
                       @click="sendSecurity">
                    重新发送
                    <span v-if="reckon_time_ent">( {{ reckon_time }}s )</span>
                  </div>
                </div>
                </Col>
              </Row>
            </FormItem>
            <FormItem>
              <Row type="flex"
                   justify="center"
                   class="lg">
                <input type="button"
                       value="下一步"
                       class="logIn"
                       @click="nextStep()" />
              </Row>
            </FormItem>
          </Form>
          </Col>
        </Row>

        <div class="Powered">
          <h3 style="">Powered by</h3>
          <img src="../assets/img/dji@2x.png"
               alt="" />
        </div>
      </div>
      <!--填写信息-->
      <div class="infoCentent"
           v-if="pageStatus == 3">
        <div class="title">
          <h1>注册</h1>
          <h2>Register</h2>
        </div>
        <Row type="flex"
             justify="center"
             class="lg"
             class-name="infoBox">
          <Col span="16"
               class="lg">
          <Form ref="infoForm"
                :model="infoForm"
                :rules="infoRule"
                id="infoForm">
            <FormItem prop="password">
              <Row type="flex"
                   justify="center"
                   class="lg">
                <Col span="24"
                     class="lg">
                <Input v-model="infoForm.password"
                       type="password"
                       placeholder="密码"></Input>
                </Col>
              </Row>
            </FormItem>
            <FormItem prop="password2">
              <Row type="flex"
                   justify="center"
                   class="lg">
                <Col span="24"
                     class="lg">
                <Input v-model="infoForm.password2"
                       type="password"
                       placeholder="确认密码"></Input>
                </Col>
              </Row>
            </FormItem>
            <FormItem prop="name">
              <Row type="flex"
                   justify="center"
                   class="lg">
                <Col span="24"
                     class="lg">
                <Input type="text"
                       v-model="infoForm.name"
                       placeholder="用户名"></Input>
                </Col>
              </Row>
            </FormItem>
            <FormItem prop="pm_name">
              <Row type="flex"
                   justify="center"
                   class="lg">
                <Col span="24"
                     class="lg">
                <Input type="text"
                       v-model="infoForm.pm_name"
                       placeholder="公司名称"></Input>
                </Col>
              </Row>
            </FormItem>
            <FormItem prop="head_icon">
              <Row type="flex"
                   class="lg"
                   class-name="infoUpload">
                <div class="upload-list"
                     v-for="item in uploadList">
                  <template v-if="item.status === 'finished'">
                    <img :src="item.url" />
                    <div class="upload-list-cover">
                      <Icon type="ios-trash-outline"
                            @click="handleRemove(item)"></Icon>
                    </div>
                  </template>
                </div>
                <Upload v-if="uploadList.length == 0"
                        ref="upload"
                        :show-upload-list="false"
                        :on-success="handleSuccess"
                        :format="['jpg', 'jpeg', 'png']"
                        :max-size="2048"
                        :on-format-error="handleFormatError"
                        :on-exceeded-size="handleMaxSize"
                        :before-upload="uploadBefore"
                        multiple
                        type="drag"
                        action
                        style="display: inline-block; width: 58px">
                  <div style="width: 58px; height: 58px; line-height: 58px">
                    <Icon type="ios-camera"
                          size="20"></Icon>
                  </div>
                </Upload>
                <span>公司图标</span>
              </Row>
            </FormItem>
            <FormItem>
              <Row type="flex"
                   justify="center"
                   class="lg">
                <input type="button"
                       value="完成注册"
                       class="register"
                       @click="register()" />
              </Row>
            </FormItem>
          </Form>
          </Col>
        </Row>
        <div class="Powered">
          <h3 style="">Powered by</h3>
          <img src="../assets/img/dji@2x.png"
               alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import '../assets/font/font.css';
import axios from 'axios';

export default {
  name: 'login',
  data: function () {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.infoForm.password2 !== '') {
          // 对第二个密码框单独验证
          this.$refs.infoForm.validateField('password2');
        }
        callback();
      }
    };
    const validatePassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.infoForm.password) {
        callback(new Error('两个输入密码不匹配!'));
      } else {
        callback();
      }
    };

    return {
      pageStatus: 1, //1.登录2.注册->手机验证3.信息填写
      swiperOption: {
        //循环
        loop: true,
        //设定初始化时slide的索引
        initialSlide: 0,
        //自动播放
        autoplay: {
          delay: 5000,
          stopOnLastSlide: false,
          disableOnInteraction: false,
        },
        //滑动速度
        speed: 1500,
        //滑动方向
        direction: 'horizontal',
        //小手掌抓取滑动
        grabCursor: true,
      },
      // 表单
      formInline: {
        user: '',
        password: '',
        checkbox: ['记住密码'],
      },
      ruleInline: {
        user: [
          { required: true, message: '请填写用户名', trigger: 'blur' },
          {
            type: 'string',
            min: 6,
            max: 11,
            message: '用户名长度 6 - 11个字符',
          },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, max: 12, message: '用户名长度 6 - 12个字符' },
        ],
      },
      reckon_time: 30,
      reckon_time_shou: false,
      reckon_time_ent: true,
      // 获取验证码表单
      verifyForm: {
        phone: '',
        smscode: '',
      },
      verifyRule: {
        phone: [
          { required: true, message: '请填写注册手机' },
          {
            type: 'string',
            pattern: /^[1]([3-9])[0-9]{9}$/,
            message: '请输入正确的手机号码',
          },
        ],
        smscode: [
          { required: true, message: '请输入验证码' },
          {
            type: 'string',
            pattern: /^[0-9]{4}$/,
            message: '请输入正确的验证码',
          },
        ],
      },
      reckon_time_timer: null,
      registerPhone: '', //注册的手机号
      infoForm: {
        name: '',
        password: '',
        password2: '',
        pm_name: '',
        head_icon: '',
      },
      infoRule: {
        name: [
          { required: true, message: '请填写用户名', trigger: 'blur' },
          { min: 4, max: 12, message: '用户名长度 4 - 12个字符' },
        ],
        password: [{ validator: validatePass, trigger: 'blur' }],
        password2: [{ validator: validatePassCheck, trigger: 'blur' }],
        pm_name: [
          { required: true, message: '请填写公司名称', trigger: 'blur' },
          { min: 4, max: 12, message: '公司名称长度 4 - 12个字符' },
        ],
      },
      uploadList: [],
      IPlocation: '',
    };
  },
  mounted() {
    // 记住密码
    if (typeof sessionStorage.account != 'undefined') {
      this.formInline.user = sessionStorage.account;
      this.formInline.password = sessionStorage.pwd;
    }

    var _this = this;
    // 根据IP地址获取坐标
    axios
      .get(
        'https://restapi.amap.com/v3/ip',
        {
          params: {
            key: '4fd128de3f3dab6b7fdfab09ea6da317',
            output: 'json',
          },
        },
        {
          timeout: 1000,
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          },
        }
      )
      .then(function (res) {
        console.log(res);
        if (res.status == 200) {
          console.log(res.data.rectangle);
          var locations = res.data.rectangle.split(';');
          var southwest = locations[0].split(',');
          var northeast = locations[1].split(',');
          console.log(southwest, northeast);
          _this.IPlocation = [
            (Number(southwest[0]) + Number(northeast[0])) / 2,
            (Number(southwest[1]) + Number(northeast[1])) / 2,
          ];
          console.log(_this.IPlocation);
        } else {
          console.log(res.statusText);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
  methods: {
    login() {
      sessionStorage.clear();
      var _this = this;
      this.$refs['formInline'].validate((valid) => {
        if (valid) {
          _this
            .$post('/admin/login/Login', {
              account: _this.formInline.user,
              password: _this.formInline.password,
              // password: _this.formInline.password,
              client: 'web',
            })
            .then((res) => {
              if (res.code == 0) {
                _this.$Notice.error({
                  title: '登录提示',
                  desc: res.msg_customer,
                });
              } else {
                // 储存数据
                sessionStorage.setItem(
                  'user_info',
                  JSON.stringify(res.data.user_info)
                );
                let token = '?token=' + res.data.token;
                sessionStorage.setItem('token', token);
                // let type = res.data.user_info.type;
                // sessionStorage.setItem('userType', type);
                if (res.data.user_info.is_manager === 0) {
                  sessionStorage.setItem('userType', 2);
                } else {
                  sessionStorage.setItem('userType', 1);
                }
                // 跳转页面
                if (typeof res.data.user_info.name != 'undefined') {
                  _this.$router.push({
                    path: '/live/watch-lists',
                  });
                }
                // 记住密码
                if (_this.formInline.checkbox.length > 0) {
                  sessionStorage.account = _this.formInline.user;
                  sessionStorage.pwd = _this.formInline.password;
                }
              }
            });
        } else {
          this.$Message.error('请正确填写用户名和密码');
        }
      });
    },
    // 发送验证码
    sendSecurity() {
      if (this.reckon_time_shou && this.reckon_time_ent) {
        return;
      }
      this.$refs.verifyForm.validateField('phone', (msg) => {
        if (msg !== '') {
          return;
        } else {
          this.$post('/base/common/tencentCloudSendSms', {
            client: 'web',
            phone: this.verifyForm.phone,
          }).then((res) => {
            console.log(res);
            if (res.code == 1) {
              if (res.msg_customer == '此号码已被注册') {
                this.$Message.info(res.msg_customer);
                return;
              }
              this.$Message.info(res.msg_customer);
              this.reckon_time_shou = true;
              this.reckon_time_ent = true;
              this.reckon_time_timer = setInterval(() => {
                if (this.reckon_time == 0) {
                  clearInterval(this.reckon_time_timer);
                  this.reckon_time = 30;
                  this.reckon_time_ent = false;
                }
                this.reckon_time = this.reckon_time - 1;
              }, 1000);
            } else {
              this.$Message.error(res.msg_customer);
            }
          });
        }
      });
    },
    //下一步
    nextStep() {
      var _this = this;
      this.$refs['verifyForm'].validate((valid) => {
        if (valid) {
          _this
            .$post('/base/common/checkSmsCode', {
              phone: this.verifyForm.phone,
              smscode: this.verifyForm.smscode,
              client: 'web',
            })
            .then((res) => {
              console.log(res);
              if (res.code == 1) {
                this.registerPhone = this.verifyForm.phone;
                this.pageStatus = 3;
                this.$refs['verifyForm'].resetFields();
              } else {
                this.$Message.error(res.msg_customer);
              }
            });
        } else {
          this.$Message.error('请正确填写用户名和密码');
        }
      });
    },
    // 公司头像
    handleSuccess(res, file) {
      file.url =
        'https://o5wwk8baw.qnssl.com/7eb99afb9d5f317c912f08b5212fd69a/avatar';
      file.name = '7eb99afb9d5f317c912f08b5212fd69a';
    },
    handleFormatError(file) {
      this.$Notice.warning({
        title: '文件格式不正确',
        desc: '文件格式请选择jpg或png.',
      });
    },
    handleMaxSize(file) {
      this.$Notice.warning({
        title: '超过限定大小限制',
        desc: '上传的图片大小不得大于2M.',
      });
    },
    //上传用户头像
    uploadBefore(file) {
      console.log(file);
      if (file) {
        let fm = new FormData();
        fm.append('client', 'web');
        fm.append('file', file);
        fm.append('use_type', 5);
        this.$fromdata('/base/common/imageUp', fm).then((res) => {
          if (res.code == 1) {
            this.infoForm.head_icon = res.data[0];
            this.uploadList.push({
              status: 'finished',
              url: res.data[0],
            });
          } else {
            this.$Message.error(res.msg_customer);
          }
        });
      }
    },
    // 重置此头像
    handleRemove(item) {
      console.log('点击了删除');
      this.uploadList.splice(this.uploadList.indexOf(item), 1);
    },
    //注册
    register() {
      var _this = this;
      this.$refs['infoForm'].validate((valid) => {
        if (valid) {
          _this
            .$post('/admin/first_pm/adminUserRegister', {
              client: 'web',
              pm_name: this.infoForm.pm_name,
              name: this.infoForm.name,
              location: JSON.stringify(this.IPlocation),
              account: this.registerPhone,
              password: this.infoForm.password,
              head_icon: this.infoForm.head_icon,
            })
            .then((res) => {
              console.log(res);
              if (res.code == 1) {
                this.pageStatus = 1;
                this.$Message.info(res.msg_customer);
                this.$refs['infoForm'].resetFields();
              } else {
                this.$Message.error(res.msg_customer);
              }
            });
        } else {
          this.$Message.error('请正确填写用户名和密码');
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.main {
  @include wh(1200, 748);
  @include center;
  box-shadow: 2px 3px 6px rgba(0, 0, 0, 0.16);
  display: flex;

  .wrapper {
    @include wh(720, 748);
    margin: 0;

    .swiper-item {
      @include wh(720, 748);
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  .loginCentent {
    padding-top: pcRem(150);
    width: pcRem(480);
    position: relative;

    h1 {
      @include sc(28, #333);
      text-align: center;
      font-weight: bold;
      /*margin-bottom: pcRem(6);*/
      font-family: 'SY';
      margin-bottom: pcRem(56);
    }

    h2 {
      @include sc(24, #757172);
      text-align: center;
      font-weight: bold;
      margin-bottom: pcRem(56);
    }

    #loginForm {
      .icomBox {
        width: 100%;
        height: 100%;
        border-bottom: solid 1px #707070;

        img {
          @include center;
          width: pcRem(16);
          height: auto;
        }
      }

      .logIn {
        @include wh(274, 36);
        line-height: pcRem(36);
        background-color: #333333;
        @include sc(18, #fff);
        letter-spacing: pcRem(4);
        cursor: pointer;
        border: none;
      }
    }
  }

  #loginForm {
    /deep/ .ivu-input {
      /*修改登录input样式*/
      outline: none;
      border: none;
      border-bottom: 1px #707070 solid;
      border-radius: 0px;
    }

    /deep/ .ivu-input:focus {
      outline: none;
      border: none;
      box-shadow: 0 0 0 2px transparent;
      border-bottom: 1px #707070 solid;
    }
  }

  .verifyCentent {
    padding-top: pcRem(150);
    width: pcRem(480);
    position: relative;

    .title {
      margin-bottom: pcRem(46);

      h1 {
        color: #333333;
        font-size: pcRem(28);
        text-align: center;
        margin-bottom: 4px;
      }

      h2 {
        color: #757172;
        font-size: pcRem(22);
        text-align: center;
      }
    }

    .verifyBox {
      .securityBox {
        position: relative;
        margin-top: pcRem(16);

        .security {
          cursor: pointer;
          position: absolute;
          right: 0;
          bottom: 6px;
          width: pcRem(120);
          height: pcRem(28);
          background-color: #2196f3;
          @include sc(14, #fff);
          text-align: center;
          line-height: pcRem(28);

          span {
            font-size: 12px;
          }
        }

        .bgc {
          background-color: #7d7d7d;
        }
      }
    }
  }

  #verifyForm {
    .logIn {
      margin-top: pcRem(50);
      @include wh(230, 36);
      line-height: pcRem(36);
      background-color: #333333;
      @include sc(18, #fff);
      letter-spacing: pcRem(4);
      cursor: pointer;
      border: none;
    }

    /deep/ .ivu-input {
      /*修改登录input样式*/
      outline: none;
      border: none;
      border-bottom: 1px #707070 solid;
      border-radius: 0px;
    }

    /deep/ .ivu-input:focus {
      outline: none;
      border: none;
      box-shadow: 0 0 0 2px transparent;
      border-bottom: 1px #707070 solid;
    }
  }

  .infoCentent {
    padding-top: pcRem(50);
    width: pcRem(480);
    position: relative;

    .title {
      margin-bottom: pcRem(20);

      h1 {
        color: #333333;
        font-size: pcRem(28);
        text-align: center;
        margin-bottom: 4px;
      }

      h2 {
        color: #757172;
        font-size: pcRem(22);
        text-align: center;
      }
    }
    .upload-list {
      display: inline-block;
      width: 60px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      border: 1px solid transparent;
      border-radius: 4px;
      overflow: hidden;
      background: #fff;
      position: relative;
      box-shadow: 0 3px 6px #eee;
      margin-right: 4px;
    }
    .upload-list img {
      width: 100%;
      height: 100%;
    }
    .upload-list-cover {
      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.6);
    }
    .upload-list:hover .upload-list-cover {
      display: block;
    }
    .upload-list-cover i {
      color: #fff;
      font-size: 20px;
      cursor: pointer;
      margin: 0 2px;
    }
  }

  #infoForm {
    .register {
      @include wh(230, 36);
      line-height: pcRem(36);
      background-color: #333333;
      @include sc(18, #fff);
      letter-spacing: pcRem(4);
      cursor: pointer;
      border: none;
    }
    .infoUpload {
      margin-bottom: pcRem(20);
      > span {
        font-size: pcRem(16);
        line-height: 58px;
        margin-left: 10px;
      }
    }

    /deep/ .ivu-input {
      /*修改登录input样式*/
      outline: none;
      border: none;
      border-bottom: 1px #707070 solid;
      border-radius: 0px;
      margin-top: pcRem(20);
    }

    /deep/ .ivu-input:focus {
      outline: none;
      border: none;
      box-shadow: 0 0 0 2px transparent;
      border-bottom: 1px #707070 solid;
    }
  }

  .Powered {
    width: 100%;
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    h3 {
      @include sc(16);
      margin-right: 6px;
    }

    img {
      width: pcRem(90);
      height: auto;
    }
  }
}
</style>
